<template>
  <div class="addingFloor">
    <input type="text" class="newFlatInput" ref="newFlat" v-if="addingFlat"
           v-model="newFlatName" @blur="hideAdding" placeholder="№"
           @keyup.enter="createFlat(block.id,entrance.entrance,floor[0].floor,newFlatName)" @keyup.esc="hideAdding" />
    <div class="add-flat" v-if="!addingFlat" @click="startAdding">&plus;</div>
    <div v-if="addingFlat" class="create-block-btn"
         @click="createFlat(block.id,entrance.entrance,floor[0].floor,newFlatName)">
      <img src="../../assets/images/Active.svg" alt="">
    </div>
    <div v-if="addingFlat" class="cancel-block-btn" @click="hideAdding">
      <img src="../../assets/images/cancel-btn.svg" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "AddFlat",
  props:{
    block:{
      type:Object,
      default:() => {},
    },
    entrance:{
      type:Object,
      default:() => {},
    },
    floor:{
      type:Array,
      default:() => [],
    },
  },
  data(){
    return{
      addingFlat:false,
      newFlatName:'',
    }
  },
  methods:{
    startAdding(){
      console.log(this.block.number,this.entrance.entrance,this.floor[0].floor)
      this.addingFlat=true;
      this.$nextTick(() => this.$refs.newFlat.focus());
    },
    hideAdding(){
      setTimeout(()=>{
        this.addingFlat=false;
        this.newFlatName="";
      },200);
    },
    createFlat(block,entrance,floor,newFlatName){
      this.$emit('create',block,entrance,floor,newFlatName);
      this.hideAdding()
    }
  }
}
</script>

<style scoped>
.addingFloor{
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: center;
}
.newFlatInput{
  width: 32px;
  background-color: #F0F0F3;
  height: 32px;
  margin-left: 0;
  padding: 5px;
  border-radius: 4px;
  /*font-size: 32px;*/
}
</style>
